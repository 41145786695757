import Vue from 'vue';
import VueRouter from 'vue-router';
import index from '../views/index.vue'
import login from '../views/account/login.vue';

Vue.use(VueRouter)

const routes = [
	// 主页ss
	{
		path: '/',
		name: 'index',
		component: index
	},
	// 登录
	{
		path: '/account/login',
		name: 'login',
		component: login
	},
	// 忘记密码
	{
		path: '/account/forgot',
		name: 'forgot',
		component: () => import('../views/account/forgot.vue')
	},
	// 注册账号
	{
		path: '/account/register',
		name: 'register',
		component: () => import('../views/account/register.vue')
	},
	// 媒体图片
	{
		path: '/media/image',
		name: 'media_image',
		component: () => import('../views/media/image.vue')
	},
	// 音乐
	{
		path: '/media/music',
		name: 'media_music',
		component: () => import('../views/media/music.vue')
	},
	// 媒体视频
	{
		path: '/media/video',
		name: 'media_video',
		component: () => import('../views/media/video.vue')
	},
	// 文章路由
	{
		path: '/article/list',
		name: 'article_list',
		component: () => import('../views/article/list.vue')
	},
	{
		path: '/article/details',
		name: 'article_details',
		component: () => import('../views/article/details.vue')
	},
	// 浏览网站
	// 收藏路由
	{
		path: '/user/collect',
		name: 'collect_list',
		component: () => import('../views/user/collect.vue')
	},
	// 公告路由
	{
		path: '/notice/list',
		name: 'notice_list',
		component: () => import('../views/notice/list.vue')
	},
	{
		path: '/notice/details',
		name: 'notice_details',
		component: () => import('../views/notice/details.vue')
	},
	//房间管理列表路由
	{
		path: '/room_management/list',
		name: '/room_management_list',
		component: () => import('../views/room_management/list.vue')
	},
	//房间管理详情路由
	{
		path: '/room_management/details',
		name: '/room_management_details',
		component: () => import('../views/room_management/details.vue')
	},
	//技师列表路由
	{
		path: '/technician/list',
		name: '/technician_list',
		component: () => import('../views/technician/list.vue')
	},
	//订单中心添加路由
	{
		path: '/order_center/edit',
		name: '/order_center_edit',
		component: () => import('../views/order_center/edit.vue')
	},
	//商品管理列表路由
	{
		path: '/commodity_management/list',
		name: '/commodity_management_list',
		component: () => import('../views/commodity_management/list.vue')
	},
	//商品管理详情路由
	{
		path: '/commodity_management/details',
		name: '/commodity_management_details',
		component: () => import('../views/commodity_management/details.vue')
	},
	//我要订房添加路由
	{
		path: '/i_want_to_make_a_reservation/edit',
		name: '/i_want_to_make_a_reservation_edit',
		component: () => import('../views/i_want_to_make_a_reservation/edit.vue')
	},
	//留言箱添加路由
	{
		path: '/message_box/edit',
		name: '/message_box_edit',
		component: () => import('../views/message_box/edit.vue')
	},

	// 用户路由
	{
		path: '/user/index',
		name: 'user_index',
		component: () => import('../views/user/index.vue')
	},
	// 基本信息
	{
		path: '/user/info',
		name: 'user_info',
		component: () => import('../views/user/info.vue')
	},
	// 找回密码
	{
		path: '/user/password',
		name: 'user_password',
		component: () => import('../views/user/password.vue')
	},

	// 搜索
	{
		path: '/search',
		name: 'search',
		component: () => import('../views/search/index.vue')
	},
	// 局部搜索
	{
		path: '/search/details',
		name: 'search_details',
		component: () => import('../views/search/details.vue')
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.afterEach((to, from, next) => {
	let title = "推拿服务管理系统-home";
	document.title = title;
	document.logo = "推拿服务管理系统"
})

export default router
